import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextLight, TextMedium } from "../../base/BaseText";
import BaseLink from "../../base/BaseLink/BaseLink";
import BaseButton from "../../base/BaseButton/BaseButton";
import { ReactComponent as ArrowStep } from "../../images/home/icon-arrow-step.svg";
import DamagedPhoneImage from "../../images/home/image-damaged-phone.svg";
import DamagedPhoneStraightImage from "../../images/home/image-damaged-phone-straight.svg";
import UserIcon from "../../images/home/icon-user.svg";
import RepairedScreenIcon from "../../images/home/icon-repaired-screen.svg";
import CrackedScreenIcon from "../../images/home/icon-cracked-screen.svg";
import CrackedScreenHandIcon from "../../images/home/icon-cracked-screen-hand.svg";
import BannerImage from "../../images/home/image-home-banner2.svg";
import { Link } from "react-router-dom";

import BasePopup from "../../base/BasePopup/BasePopup";
import moment from 'moment';
import surIcon from "../../images/home/cracked-phone.png";
import { useTranslation, Trans } from "react-i18next";

const HomeBody = ({ onGetStarted }) => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.open(
      "https://kb-prod.sea.soluto.asurion.com/s_article/prepare-your-phone-for-screen-repair/"
    );
  };

  const surCampaignStart = process.env.REACT_APP_SUR_CAMPAIGN_START;
  const surCampaignEnd = process.env.REACT_APP_SUR_CAMPAIGN_END;
  const isSurCampaignEndDateActive = moment().isSameOrBefore(moment(surCampaignEnd), "day")
  const isSURCampaign = process.env.REACT_APP_SUR_CAMPAIGN_ENABLED === 'true' && isSurCampaignEndDateActive;

  // console.log("isSURCampaign", isSURCampaign)
  
  const [showSGCampaignPopup, setShowSGCampaignPopup] = useState(isSURCampaign);

  const Banner = () => (
    <article className="bg-banner h-full relative">
      <div>
        <div className="lg:absolute top-0 bottom-0 right-0 h-full z-0 mx-auto">
          <img
            className="object-cover h-full p-0 hidden lg:block"
            src={BannerImage}
            alt="Banner"
          />
        </div>
        <div className="bg-banner lg:bg-transparent mx-auto lg:p-8 relative lg:w-4/5 text-center lg:text-left text-srgray-secondary">
          <div className="text-3xl pt-12 lg:pt-8">
            Phone screen ruined? Your day isn’t.
          </div>
          <div className="text-5xl lg:text-6xl px-8 lg:px-0">
            <TextMedium>Get your cracked screen repaired.</TextMedium>
          </div>
          <div className="h-2 bg-app-primary w-24 my-8 mx-auto lg:mx-0"></div>
          <div className="text-3xl mt-6 lg:my-8 px-8 lg:px-0">
            The phone you love, made good as new.
            <br />
            Get fast, convenient screen repair <br className="lg:hidden" /> {" "}
            <TextMedium>from $99.</TextMedium>
          </div>
          <div>
            <BaseButton
              text="Get Started"
              className="w-52 px-8 mx-auto lg:mx-0 mt-8 mb-12 lg:my-16"
              focused
              onClick={onGetStarted}
            />
          </div>
          <div className="hidden lg:block h-16 overflow-visible w-4/5">
            <div className="w-40%">
              <img
                src={DamagedPhoneImage}
                className="h-65r xl:h-60r s1366:h-56r s1600:h-50r hidden lg:block absolute"
                alt="Damaged Phone"
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );

  const Services = () => (
    <article className="px-12 pb-12 bg-srgray-septenary w-full">
      <div className="text-center text-5xl w-full">
        <div className="mb-8">
          <TextLight>4 Simple Steps</TextLight>
        </div>
        <div className="flex justify-center flex-row flex-wrap text-srgray-secondary">
          <div className="flex flex-row flex-wrap justify-center">
            <div className="text-left ml-4 flex my-4">
              <img
                className="mb-auto mt-4"
                src={CrackedScreenHandIcon}
                alt="Device Eligibility"
              />
              <div className="w-20r ml-8 mr-2">
                <div className="text-2xl my-4">
                  <TextMedium>Ensure your phone is eligible.</TextMedium>
                </div>
                {/* <div className="text-default">
                  <TextLight>
                  Currently available for all mobile phones (except Nothing Phone 1, Nothing Phone 2, Huawei Mate Xs and Huawei P50 Pocket)
                  </TextLight>
                </div> */}
                <div className="text-default">
                  <TextLight>
                  Currently available for all mobile phones (except for HUAWEI Mate XS, HUAWEI P50 Pocket and all models of NOTHING)
                  </TextLight>
                </div>
              </div>
            </div>
            {/* <ArrowStep className="hidden s1366:block w-14" /> */}
            <div className="text-left ml-4 flex my-4">
              <img
                className="mb-auto mt-4"
                src={CrackedScreenIcon}
                alt="Cracked Screen"
              />
              <div className="w-20r ml-8 mr-2">
                <div className="text-2xl my-4">
                  <TextMedium>Prepare your phone</TextMedium>
                </div>
                <div className="text-default">
                  <TextLight>
                    Backup your phone, turn off activation lock, perform data
                    wipe and factory reset
                    <br />
                    <br />
                    <Link to="/prepare" target="blank">
                      <BaseLink className="text-app-primary">
                        <TextMedium>Click here</TextMedium>
                      </BaseLink>{" "}
                    </Link>
                    for more info
                  </TextLight>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-center">
            {/* <ArrowStep className="hidden s1366:block w-14" /> */}
            <div className="text-left ml-4 flex my-4">
              <img
                className="mb-auto mt-4 w-32"
                src={UserIcon}
                alt="Schedule Pick-up"
              />
              <div className="w-20r ml-8 mr-2">
                <div className="text-2xl my-4">
                  <TextMedium>Schedule for pick-up</TextMedium>
                </div>
                <div className="text-default">
                  <TextLight>
                    Your device will be picked up and sent to repair centre for
                    physical assessment of Screen Repair suitability.
                  </TextLight>
                </div>
              </div>
            </div>
            {/* <ArrowStep className="hidden s1366:block w-14" /> */}
            <div className="text-left ml-4 flex my-4">
              <img
                className="mb-auto mt-4"
                src={RepairedScreenIcon}
                alt="Repaired Screen"
              />
              <div className="w-20r ml-8 mr-2">
                <div className="text-2xl my-4">
                  <TextMedium>Return repaired phone</TextMedium>
                </div>
                <div className="text-default">
                  <TextLight>
                    Screen Repair will take 2 business days. Your device will be
                    delivered to you and repair fee will be charged upon
                    completion.
                  </TextLight>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-12 px-4 text-2xl max-w-130r mx-auto">
        <TextLight>
          Note: Access limited to M1 FoneCare+ subscribers only. Screen Repair is
          offered as a separate service to M1 Fonecare+. Your device will be
          ineligible for a screen repair if there is any other damage to your
          device in addition to screen damage.
        </TextLight>
      </div>
    </article>
  );

  const RepairService = () => (
    <article className="bg-srgray-septenary text-white">
      <div>
        <div className="bg-app-primary">
          <div className="p-8 mx-auto w-4/5 flex flex-row-reverse">
            <div className="lg:w-50% xl:w-60% my-auto">
              <div className="text-3.5 pt-8">
                <TextLight>What is a Screen Repair Service?</TextLight>
              </div>
              <div className="text-2.5 pt-8 pb-6">
                Get your cracked screen repaired from $99:
              </div>
              <div className="text-3xl">
                <TextLight>
                  • Hassle-free service with free pick-up and delivery <br />•
                  Fast and convenient with your repaired phone returned within
                  2 days from pick-up
                  <br /> • Peace of mind with free 12 months warranty against 
                  manufacturer defects and malfunctions
                </TextLight>
              </div>
              <div className="text-xl mt-8 pb-4">
                <TextLight>
                Do note that manufacturing malfunctions or defects does not include battery degradation. 
                Warranty starts from the date the mobile phone is returned to you​
                </TextLight>
              </div>
              <div className="lg:hidden w-full pt-8 pr-8 h-24r">
                <img
                  src={DamagedPhoneStraightImage}
                  className="mx-auto"
                  alt="Damaged Phone Straight"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-7r bg-srgray-septenary"></div>
      </div>
    </article>
  );

  const startDateLabel = moment(surCampaignStart).format("D MMM")
  const endDateLabel = moment(surCampaignEnd).format("D MMM")
  const yearLabel = moment(surCampaignEnd).format("YYYY")

  return (
    <div className="flex flex-col">
      <Banner />
      <RepairService />
      <Services />

      {showSGCampaignPopup &&
        <div className={`block z-50`}>
          <BasePopup
            show={true}
            onClose={() => setShowSGCampaignPopup(false)}
            global
            contentClassname="campaign-modal overflow-auto sm:rounded-3xl w-full h-full sm:w-640px sm:h-auto"
          >
            
            <div className="mt-0 mx-auto text-center relative h-90% sm:h-auto">

              <div className="flex sm:hidden absolutex flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                style={{ top: "0", left: "0", width: "110px", height: "110px", background: "#FF9E1B" }}>
                <Trans i18nKey={`Campaign.BadgeLabel`}>
                  <span></span>
                </Trans>
              </div>

              <div className="hidden sm:flex absolute flex-col items-center justify-center text-14px leading-tight text-white rounded rounded-full"
                style={{ top: "-20px", width: "110px", height: "110px",  background: "#FF9E1B" }}>
                <Trans i18nKey={`Campaign.BadgeLabel`}>
                  <span></span>
                </Trans>
              </div>

              <div className="absolutex pt-6 sm:pt-0 sm:static transform top-1/2 -Trans-y-1/2x sm:Trans-y-0">
                <div className="flex justify-center items-center mb-6 pt-10px sm:pt-60px w-90% sm:w-1/2 mx-auto">

                  <p className="mt-0 text-5xl font-bold text-center ml-auto inline-block leading-tight">
                    <span style={{ paddingRight: "15px" }} className="block w-auto sm:w-270px" dangerouslySetInnerHTML={{ __html: t("Campaign.Title") }}></span>
                  </p>

                  <div className="mr-auto">
                    <div style={{ width: "75px" }}>
                      <img className="mr-auto" src={surIcon} style={{ width: "75px" }} />
                    </div>
                  </div>

                </div>


                <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                  <Trans i18nKey={`Campaign.SubtitleLine1`}>
                  </Trans>
                </p>

                <p className="text-center text-14px px-5 sm:px-0 mx-auto">
                  <Trans i18nKey={`Campaign.SubtitleLine2`}>
                  </Trans>
                </p>

                <p className="text-center text-2xl px-5 sm:px-0 my-6 mx-auto font-bold" style={{color: "#FF9E1B"}}>
                  <Trans i18nKey={`Campaign.DiscountNote`}>
                  </Trans>
                </p>
                
                <p className="text-center text-12px px-2 sm:px-5 pb-4" style={{ color: "#A5AAAF" }}>
                  {t("Campaign.Note", { startDate: startDateLabel, endDate: endDateLabel, year: yearLabel })}
                </p>
              </div>

            </div>

          </BasePopup>
        </div>
      }
    </div>
  );
};

HomeBody.propTypes = {
  onGetStarted: PropTypes.func.isRequired,
};

export default HomeBody;
